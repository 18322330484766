<!--/**-->
<!--* 自动生成 vue列表代码【模块名：平台订单数据】-->
<!--* 生成日期：2024/11/21 14:37:32-->
<!--* 生成路径: src/pages/trade/TradeList.vue-->
<!--* 生成人：管理员-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini' label-width='90px' label-position='right'
                   label-suffix=''>
            <el-form-item label='匹配状态'>
              <el-radio-group v-model="searchCondition.status_match" size="mini">
                <el-radio-button :label="``">全部</el-radio-button>
                <el-radio-button :label="`Y`">已匹配</el-radio-button>
                <el-radio-button :label="`N`">未匹配</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label='导入文件'>
              <el-select v-model='searchCondition.import_id' :placeholder='`可选导入记录`'
                         style='width: 300px' multiple :collapse-tags='true'
                         clearable>
                <el-option v-for='(item,key) in options.files'
                           :label='item.label' :value='item.value'
                           :key='key'>
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='可选平台'>
              <el-select v-model='searchCondition.platform_code' :placeholder='`可选平台`'
                         style='width: 200px' multiple :collapse-tags='true'
                         clearable>
                <el-option v-for='(item,key) in options.platform'
                           :label='item.label' :value='item.value'
                           :key='key'>
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='匹配红人'>
              <el-select v-model='searchCondition.artist_id' :placeholder='`可选已匹配红人`'
                         style='width: 200px' multiple :collapse-tags='true'
                         clearable>
                <el-option v-for='(item,key) in options.kol'
                           :label='item.label' :value='item.value'
                           :key='key'>
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <div v-show='showMore' style='display: inline'>
              <el-form-item label='订单 id'>
                <el-input v-model='searchCondition.order_id' placeholder='订单id' clearable></el-input>
              </el-form-item>
              <el-form-item label='商品 id'>
                <el-input v-model='searchCondition.product_id' placeholder='商品id' clearable></el-input>
              </el-form-item>
              <el-form-item label='商品名称'>
                <el-input v-model='searchCondition.product_title' placeholder='商品名称' clearable></el-input>
              </el-form-item>
              <el-form-item label='订单状态'>
                <!--                <el-input v-model='searchCondition.order_status' placeholder='订单状态' clearable></el-input>-->
                <el-select v-model='searchCondition.order_status' :placeholder='`可选订单状态`'
                           style='width: 200px' multiple :collapse-tags='true'
                           clearable>
                  <el-option v-for='(item,key) in options.order_status'
                             :label='item.label' :value='item.value'
                             :key='key'>
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='流量来源'>
                <el-input v-model='searchCondition.flow_source' placeholder='流量来源' clearable></el-input>
              </el-form-item>
              <el-form-item label='达人昵称'>
                <el-input v-model='searchCondition.kol_nickname' placeholder='达人昵称' clearable></el-input>
              </el-form-item>
              <el-form-item label='平台ID'>
                <el-input v-model='searchCondition.account_id' placeholder='平台账号ID' clearable></el-input>
              </el-form-item>
              <el-form-item label='店铺id'>
                <el-input v-model='searchCondition.shop_id' placeholder='店铺id' clearable></el-input>
              </el-form-item>
              <el-form-item label='店铺名称'>
                <el-input v-model='searchCondition.shop_name' placeholder='店铺名称' clearable></el-input>
              </el-form-item>
              <el-form-item label='订单来源'>
                <el-input v-model='searchCondition.order_source' placeholder='订单来源' clearable></el-input>
              </el-form-item>
              <el-form-item label='细分来源'>
                <el-input v-model='searchCondition.flow_sub' placeholder='流量细分来源' clearable></el-input>
              </el-form-item>
              <el-form-item label='订单类型'>
                <el-input v-model='searchCondition.order_type' placeholder='订单类型' clearable></el-input>
              </el-form-item>
              <el-form-item label='是否结算'>
                <el-input v-model='searchCondition.is_settle' placeholder='是否结算' clearable></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
              <el-button type='text' :icon='!showMore?"el-icon-caret-bottom":"el-icon-caret-top"'
                         @click='showMore=!showMore'>
                {{ !showMore ? '展开' : '隐藏' }}
              </el-button>
            </el-form-item>
            <el-form-item style='float: right'>
              <el-link type='primary' icon='el-icon-link' @click.native.stop='jumpToImport'>前往导入</el-link>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' v-if='hasAuthority("trade_create")' @click='handleAdd'>新增
            </el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-upload' v-if='hasAuthority("trade_import")' @click='openImport=true'>导入</el-button>
          </el-col>
          <el-col :span='1.5'>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("trade_export")'
                        @export='handleExport'></export-btn>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList' border>
            <!--            <el-table-column align='center' width='120' label='操作'>-->
            <!--              <template slot-scope='{row,$index}'>-->
            <!--                <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("trade_edit")' @click='handleEdit(row)'>-->
            <!--                  修改-->
            <!--                </el-button>-->
            <!--                <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("trade_delete")'-->
            <!--                           @click='handleDelete(row,$index)'>删除-->
            <!--                </el-button>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </MlPageTable>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import { deleteTrade, exportTrades, getTradeInfo, getTradeList, optionsTrade, saveTrade } from '@/api/trade'
import { optionsTradeImported } from '@/api/trade_import'

export default {
  name: 'TradeList',
  components: { ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'trade',
      loading: false,
      loadingCommit: false,
      showMore: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {},
      options: { platform: [], files: [], order_status: [] },
      pageData: { current_page: 1, page_size: 20 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [
        { hide: false, prop: 'id', label: '数据ID', width: 88, sortable: true, fixed: false },
        { hide: true, prop: 'import_id', label: '导入ID', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'month', label: '归属月份', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'platform_code', label: '平台代号', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'platform_name', label: '归属平台', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'artist_id', label: '系统红人ID', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'artist_nickname', label: '系统红人昵称', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'idx', label: '行号', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'order_id', label: '订单 id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'product_id', label: '商品id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'product_title', label: '商品名称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'order_status', label: '订单状态', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'flow_source', label: '流量来源', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'reason_overdue', label: '超时未结算原因', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'kol_nickname', label: '达人昵称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'account_id', label: '平台账号ID', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'amount', label: '成交金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'c_rate', label: '佣金率', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'total_ci', label: '总佣金收入', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'eci_kol', label: '预估佣金收入-达人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'eci_agency', label: '预估佣金收入-机构', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'settle_amount', label: '结算金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'sci_kol', label: '结算佣金收入-达人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'sci_agency', label: '结算佣金收入-机构', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'pay_time', label: '订单支付时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'delivery_time', label: '订单收货时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'settle_time', label: '订单结算时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 's_ratio', label: '分成比例', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'ets_fee', label: '预估技术服务费', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'sts_fee', label: '结算技术服务费', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'account_type', label: '新老账户', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'product_source', label: '商品来源', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'final_pay_time', label: '尾款支付时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'deposit_amount', label: '定金金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'shop_id', label: '店铺id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'shop_name', label: '店铺名称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'product_num', label: '商品数量', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'is_bmp', label: '安心购', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'is_tiered_c', label: '是否阶梯佣金', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'c_invoice', label: '佣金发票', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'freeze_ratio', label: '冻结比例', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'threshold_sv', label: '门槛销量', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'bc_rate', label: '基础佣金率', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'up_rate', label: '升佣佣金率', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'eer_ci', label: '达人预估奖励佣金收入', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'ier_ci', label: '机构预估奖励佣金收入', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'esr_ci', label: '达人结算奖励佣金收入', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'isr_ci', label: '机构结算奖励佣金收入', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'ladder_plan_id', label: '阶梯计划ID', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'payment_subsidy', label: '支付补贴', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'platform_subsidy', label: '平台补贴', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'expert_subsidy', label: '达人补贴', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'fare', label: '运费', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'tax', label: '税费', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'fare_subsidy', label: '运费补贴', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'plan_type', label: '计划类型', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'order_source', label: '订单来源', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'flow_sub', label: '流量细分来源', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'order_type', label: '订单类型', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'spec_id', label: '规格 id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'product_price', label: '商品价格', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'live_room_id', label: '直播间 id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'live_room', label: '直播间名称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'live_start_time', label: '直播开始时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'buyer_nickname', label: '下单用户昵称', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'pay_amount', label: '用户实际支付金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'refund_amount', label: '退款金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'sales_amount', label: '有效销售金额', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'eci', label: '预估佣金', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'platform_discounts', label: '平台优惠', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'is_settle', label: '是否结算', width: 120, sortable: false, fixed: false },

        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: true, fixed: false }],

      title: '添加/修改-平台订单数据',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        import_id: [{ required: true, message: '导入记录表ID不能为空', trigger: 'blur' }],
        idx: [{ required: true, message: '序号(Excel行号)不能为空', trigger: 'blur' }],
        order_id: [{ required: true, message: '订单id不能为空', trigger: 'blur' }],
        product_id: [{ required: true, message: '商品id不能为空', trigger: 'blur' }],
        product_title: [{ required: true, message: '商品名称不能为空', trigger: 'blur' }],
        order_status: [{ required: true, message: '订单状态不能为空', trigger: 'blur' }],
        flow_source: [{ required: true, message: '流量来源不能为空', trigger: 'blur' }],
        reason_overdue: [{ required: true, message: '超时未结算原因不能为空', trigger: 'blur' }],
        kol_nickname: [{ required: true, message: '达人昵称不能为空', trigger: 'blur' }],
        kol_id: [{ required: true, message: '达人抖音号不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '成交金额不能为空', trigger: 'blur' }],
        c_rate: [{ required: true, message: '佣金率不能为空', trigger: 'blur' }],
        total_ci: [{ required: true, message: '总佣金收入不能为空', trigger: 'blur' }],
        eci_kol: [{ required: true, message: '预估佣金收入-达人不能为空', trigger: 'blur' }],
        eci_agency: [{ required: true, message: '预估佣金收入-机构不能为空', trigger: 'blur' }],
        settle_amount: [{ required: true, message: '结算金额不能为空', trigger: 'blur' }],
        sci_kol: [{ required: true, message: '结算佣金收入-达人不能为空', trigger: 'blur' }],
        sci_agency: [{ required: true, message: '结算佣金收入-机构不能为空', trigger: 'blur' }],
        pay_time: [{ required: true, message: '订单支付时间不能为空', trigger: 'blur' }],
        delivery_time: [{ required: true, message: '订单收货时间不能为空', trigger: 'blur' }],
        settle_time: [{ required: true, message: '订单结算时间不能为空', trigger: 'blur' }],
        s_ratio: [{ required: true, message: '分成比例不能为空', trigger: 'blur' }],
        ets_fee: [{ required: true, message: '预估技术服务费不能为空', trigger: 'blur' }],
        sts_fee: [{ required: true, message: '结算技术服务费不能为空', trigger: 'blur' }],
        account_type: [{ required: true, message: '新老账户不能为空', trigger: 'blur' }],
        product_source: [{ required: true, message: '商品来源不能为空', trigger: 'blur' }],
        final_pay_time: [{ required: true, message: '尾款支付时间不能为空', trigger: 'blur' }],
        deposit_amount: [{ required: true, message: '定金金额不能为空', trigger: 'blur' }],
        shop_id: [{ required: true, message: '店铺id不能为空', trigger: 'blur' }],
        shop_name: [{ required: true, message: '店铺名称不能为空', trigger: 'blur' }],
        product_num: [{ required: true, message: '商品数量不能为空', trigger: 'blur' }],
        is_bmp: [{ required: true, message: '安心购不能为空', trigger: 'blur' }],
        is_tiered_c: [{ required: true, message: '是否阶梯佣金不能为空', trigger: 'blur' }],
        c_invoice: [{ required: true, message: '佣金发票不能为空', trigger: 'blur' }],
        freeze_ratio: [{ required: true, message: '冻结比例不能为空', trigger: 'blur' }],
        threshold_sv: [{ required: true, message: '门槛销量不能为空', trigger: 'blur' }],
        bc_rate: [{ required: true, message: '基础佣金率不能为空', trigger: 'blur' }],
        up_rate: [{ required: true, message: '升佣佣金率不能为空', trigger: 'blur' }],
        eer_ci: [{ required: true, message: '达人预估奖励佣金收入不能为空', trigger: 'blur' }],
        ier_ci: [{ required: true, message: '机构预估奖励佣金收入不能为空', trigger: 'blur' }],
        esr_ci: [{ required: true, message: '达人结算奖励佣金收入不能为空', trigger: 'blur' }],
        isr_ci: [{ required: true, message: '机构结算奖励佣金收入不能为空', trigger: 'blur' }],
        ladder_plan_id: [{ required: true, message: '阶梯计划ID不能为空', trigger: 'blur' }],
        payment_subsidy: [{ required: true, message: '支付补贴不能为空', trigger: 'blur' }],
        platform_subsidy: [{ required: true, message: '平台补贴不能为空', trigger: 'blur' }],
        expert_subsidy: [{ required: true, message: '达人补贴不能为空', trigger: 'blur' }],
        fare: [{ required: true, message: '运费不能为空', trigger: 'blur' }],
        tax: [{ required: true, message: '税费不能为空', trigger: 'blur' }],
        fare_subsidy: [{ required: true, message: '运费补贴不能为空', trigger: 'blur' }],
        plan_type: [{ required: true, message: '计划类型不能为空', trigger: 'blur' }],
        order_source: [{ required: true, message: '订单来源不能为空', trigger: 'blur' }],
        flow_sub: [{ required: true, message: '流量细分来源不能为空', trigger: 'blur' }],
        order_type: [{ required: true, message: '订单类型不能为空', trigger: 'blur' }],
        spec_id: [{ required: true, message: '规格 id不能为空', trigger: 'blur' }],
        product_price: [{ required: true, message: '商品价格不能为空', trigger: 'blur' }],
        live_room_id: [{ required: true, message: '直播间 id不能为空', trigger: 'blur' }],
        live_room: [{ required: true, message: '直播间名称不能为空', trigger: 'blur' }],
        live_start_time: [{ required: true, message: '直播开始时间不能为空', trigger: 'blur' }],
        buyer_nickname: [{ required: true, message: '下单用户昵称不能为空', trigger: 'blur' }],
        pay_amount: [{ required: true, message: '用户实际支付金额不能为空', trigger: 'blur' }],
        refund_amount: [{ required: true, message: '退款金额不能为空', trigger: 'blur' }],
        sales_amount: [{ required: true, message: '有效销售金额不能为空', trigger: 'blur' }],
        eci: [{ required: true, message: '预估佣金不能为空', trigger: 'blur' }],
        platform_discounts: [{ required: true, message: '平台优惠不能为空', trigger: 'blur' }],
        is_settle: [{ required: true, message: '是否结算不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:trade'
    }
  },
  methods: {
    async loadOptions() {
      console.time('loadOptions')
      // 加载可选平台，即系统里已录入的平台
      let option1 = await optionsTradeImported()
      let option2 = await optionsTrade()
      this.options = { ...option1, ...option2 }
      console.timeEnd('loadOptions')
    },
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          // console.log(typeof this.searchCondition[field])
          condition[field] = this.searchCondition[field]
        }
      })
      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      console.time('trade_list')

      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await getTradeList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
      console.timeEnd('trade_list')
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    async delRow(id) {
      await deleteTrade(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-平台订单数据'

      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-平台订单数据'
      let { info } = await getTradeInfo(row.id)
      this.form = { ...info }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          saveTrade(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await exportTrades(searchCondition)
        let name = `平台订单数据`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    jumpToImport() {
      this.$router.push({ name: 'trade' })
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadOptions()
    }, 100)
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
